import React from "react"
import Layout from "../components/layout"
import HeaderOne from "../components/header-one"
import BannerOne from "../components/banner-one"
import Services from "../components/services"
import FeatureOne from "../components/feature-one"
import FeatureTwo from "../components/feature-two"
import Brands from "../components/brands"
import Faq from "../components/faq"
import BlogHome from "../components/blog-home"
import Subscribe from "../components/subscribe"
import Footer from "../components/footer"
import Seo from "../components/seo"

const Home = () => {
  return (
    <Layout pageTitle="Home | Klabbelanja">
      <Seo title="Home | Klabbelanja" />
      <HeaderOne />
      <BannerOne />
      {/* <FunFact /> */}
      <Brands />
      <Services />
      <FeatureTwo />
      {/* <Pricing /> */}
      {/* <Testimonials /> */}
      <Faq />
      <BlogHome />
      <Footer />
    </Layout>
  )
}

export default Home
