import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import moc from "./../assets/images/banner-moc-1-1.png"

const BannerOne = () => {
  return (
    <section className="banner-style-one" id="banner">
      <span className="bubble-1"></span>
      <span className="bubble-2"></span>
      <span className="bubble-3"></span>
      <span className="bubble-4"></span>
      <span className="bubble-5"></span>
      <span className="bubble-6"></span>
      <img src={moc} className="banner-mock" alt="Awesome alter Text" />
      <Container>
        <Row>
          <Col xl={6} lg={8}>
            <div className="content-block">
              <h3 style={{ fontWeight: 'bold' }}>
                Belanja lebih hemat sambil berbagi <br />
              </h3>
              {/* <p>
              Kini kamu bisa belanja lebih hemat pakai voucher, sambil mengembangkan bisnis dan berbagi keuntungan bersama teman dan keluarga.
              Ayo download KlabBelanja sekarang dan dapatkan bonus untuk setiap pembelian produk!
              </p> */}
              <p>
                <div>Kami menyediakan produk berbasis voucher fisik dan digital.<br />Dapatkan bonus dari setiap transaksi produk.<br />Tak perlu menunggu lagi, download sekarang di Google PlayStore!</div>
              </p>
              <div className="button-block">
                <a href="https://play.google.com/store/apps/details?id=com.klabbelanja&pcampaignid=web_share" className="banner-btn">
                  <i className="fa fa-play"></i>
                  Get in<span>Google Play</span>
                </a>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default BannerOne
